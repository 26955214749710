import $ from 'jquery';


$(document).ready(function() {
  // make menu responsive
  let menu = $('.responsive_menu ul.main_nav');
  let toggle = $('.responsive_menu .responsive_menu_toggle');
  toggle.on('click', function(event) {
    event.preventDefault();
    menu.slideToggle();
  });
  autosize($('textarea'));

});

